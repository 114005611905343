/* Styles Css */
body{
  background-image: url('/assets/venture-background.png');
  min-height: 350px;
  margin: 0;
  min-height: 100vh;
  min-width: 100vw;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden !important;
  background-color: black;
  background-position: bottom left;
}

body.keyboard {
  /* height: calc(100svh + 200px); */
}

#footer{
  opacity: 0;
  position: relative;
  z-index: 100;
}


#drawMenuResponsive{
  margin-top: 50px;
}

.buttonsControls {
  opacity: 0;
  display: flex;
  flex-direction: row-reverse;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0%);
  position: absolute;
  max-height: 105px;
  height: 10%;
  align-items: flex-end;
  display: none;
}


/* .buttonsControls::before {
    content: "Slideshow";
    border: 2px solid white;
    border-radius: 28px;
    width: 150px;
    color: white;
    display: flex;
    justify-content: center;
    margin: 0px 8px;
    align-items: center;
    font-family: 'Open sans';
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;
} */


.buttonsControlsPlus{
  height: 40px;
  width: 40px;
  color: transparent;
  background-image: url('./assets/zoom-in.svg');
  background-repeat: no-repeat;
  background-color: rgba(25, 18, 47, 0.85);
  color: transparent;
  border-radius: 28px;
  background-position: center;
  border: 2px solid white;
  background-size: 50%;
  margin:0px 8px;
  cursor: pointer;
}

.buttonsControlsPlus:disabled{
  opacity: 0.5;
}

.buttonsControlsMinus{
  height: 40px;
  width: 40px;
  color: transparent;
  background-image: url('./assets/zoom-out.svg');
  background-repeat: no-repeat;
  background-color: rgba(25, 18, 47, 0.85);
  color: transparent;
  border-radius: 28px;
  background-position: center;
  border: 2px solid white;
  background-size: 50%;
  margin:0px 8px;
  cursor: pointer;
}

.buttonsControlsMinus:disabled{
  opacity: 0.5;
}


canvas {
  /* margin-top: 50px; */
  border: 1px solid blue;
}

input{ touch-action: none}

.slide-in-bck-center {
	-webkit-animation: slide-in-bck-center 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-bck-center 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
          color:red
}

@-webkit-keyframes slide-in-bck-center {
  0% {
    -webkit-transform: translateZ(600px);
            transform: translateZ(600px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    opacity: 1;
  }
}


.react-html5-camera-photo>img, .react-html5-camera-photo>video{
  width: 900px !important;
}

.video-recorder__Video-sc-7k20rv-2{
  min-width: 70% !important;
  min-height: 70% !important;
  width: 90% !important;
  height: 90% !important;
  border-radius: 12px;
}

.video-recorder__Video-sc-7k20rv-2{
  min-width: 70% !important;
  min-height: 70% !important;
  width: 90% !important;
  height: 90% !important;
  border-radius: 12px;
}

/* .eWpLko *{
  display: none;
} */


/* span.SVGInline {
  display: none !important;
} */

.eWpLko{
  background-color: transparent !important;
}

.render-actions__ActionsWrapper-dp6lnv-0{
  bottom: 15px !important;
  top: inherit !important;
  display: flex !important;
}


.video-recorder__Wrapper-sc-7k20rv-0{
  background-color: white !important;
}

.record-button__Instructions-sc-1n5amwk-3{
  display: none !important;
  font-size: 12px !important;
  font-family: "Poppins" !important;
  font-weight: 200 !important;
}

.record-button__InstuctionsHighlight-sc-1n5amwk-4{
  color: white !important;
  display: flex;
}

.record-button__InstuctionsHighlight-sc-1n5amwk-4::after{
  content: "ORD";
}

.dloZIt{
  background-color: white !important;
  width: 44px !important;
  height: 44px !important;
}

.Tfijj {
  height: 60px !important;
  width: 60px !important;
  border-radius: 50%;
  display: none !important;
}

.fyBJcg{
  display: none !important;
}

.hFwpQm{
  display: flex !important;
}

.jfSMBu{
  display: none !important;
}


.timer__Text-avqbub-0 {
  position: absolute;
  top: 30px !important;
  right: 30px !important;
  display: flex;
  width: fit-content;
  align-items: center;
  height: fit-content;
  border-radius: 12px;
  color: #C68A12 ;
  font-size: 14px !important;
  background: rgba( 255, 255, 255, 0.86);
  text-shadow: none !important;
  padding: 1px 6px;
}


.stop-button__Border-sc-1h536gx-1{
  display: none !important;
}

.kzTnTq{
  background: #C68A12 !important;
} 

.timer__RecIcon-avqbub-1{
  background: #C68A12 !important;
}


.eOdopH{
  display: none !important;
}

.bMZpPF{
  display: none !important;
}

.video-recorder__CameraView-sc-7k20rv-1{
  min-width: 70% !important;
  min-height: 70% !important;
  width: 90% !important;
  height: 90% !important;
  border-radius: 12px;
}

.button__Button-hkteey-0{
  display: none !important;
}

/* CAMERA SWITCHER */
.switch-camera-view__SVGWrapper-sc-13l3hbu-0{
  background-color: rgba(167, 157, 201, 0.85) !important;
  display: none !important;
}

.SVGInline-svg{
  display: block !important;
  opacity: 0 !important;
}

/* .llvQhQ{
  min-width: 70% !important;
  min-height: 70% !important;
  width: 90% !important;
  height: 90% !important;
  border-radius: 12px;
}

.fHBbdT{
  display: none !important;
}

.cdBZJk{
  background-color: white !important;
} */




@media (max-width: 768px){

  .react-html5-camera-photo>img, .react-html5-camera-photo>video{
    width: 250px !important;
  }

  .eWpLko{
    min-height: 200px !important;
    width: 90% !important;
  }
}


@media only screen and (min-width: 769px) {
  .eWpLko{
    min-height: 250px !important;
  }

}