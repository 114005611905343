
.bg{
    /* background-color: rgba(28, 45, 86, 0.98); */
    height: 101vh;
    width: 100vw;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
  

.log{
    width: 100vw;
    height: 30vh;
    display: flex;
    justify-content: center;
}

.log_divs{
    width: 33.3%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}


.logIMG{
    width: 320px;
}



.card_container{
    width: 100vw;
    height: 50vh;
    min-height: 420px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.close{
    width: 50%;
    position: relative;
    top: 6%;
    right: 1%;
    cursor: pointer;
}

.close_img{
    width: 5%;
}

.card{
    opacity: 0;
    width: 380px;
    height: fit-content;
    border-radius: 12px;
    box-shadow: 0 12px 32px 8px rgba(7, 6, 34, 0.47);
    border: solid 1px #848484;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 0px;
}

.card_p1{
    width: 82%;
    font-size: 14px;
    font-family: "Poppins";
    color: #757575;
    text-align: center;
    line-height: 1.3;
    margin-top: 20px;
}

.card_p2{
    background-image: linear-gradient(90deg, #E3BE7C 0%, #C68A12 100%);
    font-family: 'Poppins';
    font-size: 23px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.96;
    letter-spacing: -0.2px;
    text-align: center;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 600;
    margin-top: 20px;
}

.swip_container{
    width: 100vw;
    height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
}



.button_container{
    width: 100vw;
    height: 15vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin-top: 10%;
    text-align: center;
}

.options_container{
    height: 150px;
    width: 150px;
}

.options_container p{
    margin: 10px 0 !important;
    width: 100%;
    font-family: 'Poppins';
    font-size: 14px;
}

.button{
    align-items: center;
    justify-content: center;
    width: 90px;
    height: 90px;
    margin: 0 20px;
    border-radius: 100%;
    box-shadow: 0 8px 30px 0 rgba(15, 7, 44, 0.07);
    background-color: #ffffff;
    border: 5px solid #ffffff;
    cursor: pointer;
    margin-bottom: 0.5rem;
}

.button img{
    width: 35px;
    display: block;
    text-align: center;
    margin: 0 auto;
}

.container_back{
    width: 80%;
    text-align: center;
    display: flex;
    margin: 0 auto;
    justify-content: center;
    margin-top: 7%;
    border-top: 1px solid #dbdbdb;
    padding: 20px 0;
}

.container_back p{
    font-family: 'Open Sans';
    font-size: 16px;
    font-weight: 600;
    color: #969696;
}

button:focus {outline:0;}

.send_pencil{
    padding-left: 7%;
}

.expP{
    color: #4D4D4D;
    font-size: 0.9vw;
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
    cursor: pointer;
    margin-top: 1.5%;
    margin-bottom: 3%;
}

.footer{
    width: 100vw;
    height: 20vh;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.footerP{
    width: 33,3%;
}

.footerSegundoP{
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
    font-size: 0.9vw;
    padding-bottom: 1%;
    color: white
}

/* MEDIA QUERIES CELLPHONE */


@media only screen and (max-width: 600px) {

    .log{
        width: 100vw;
        height: 25vh;
        display: flex;
        justify-content: center;
    }

    .log_divs{
        width: 60%;
        margin-bottom: 20px;
    }

    .logIMG{
        width: 180px;
    }
    
    .card_container{
        justify-content: flex-start;
        height: 60vh;
    }

    .card{
        width: 90%;
        height: 100%;
    }

    .card_p1{
        font-size: 15px;
    }
    
    .card_p2{
        /* font-size: 15px; */
    }

    .button_container{
        width: 100vw;
        margin-top: 14%;
    }

    .container_back{
        margin-top: 18%;
    }

    .button{
        width: 100px;
        height: 100px;
        padding: 0vh 0vh;
        font-size: 3vw;
    }

    .expP{
        font-size: 3vw;
        margin-top: 5%;
        margin-bottom: 0%;
    }

    .options_container{
        width: 40%;
    }

}


@media only screen and (max-width: 600px) and (max-height: 650px) {

    .card{
        /* height: 70%; */
    }

    .button_container{
        width: 100%;
        height: 30%;
        
    }

    .button{
        /* width: 73%;
        height: 100%; */
        padding: 0vh 0vh;
        font-size: 3vw;
        /* background-color: red; */
    }
}









@media only screen and (min-width: 700px) and (max-width: 900px) and (orientation: portrait){

    .log_divs{
        width: 70%;
    }

    .card_container{
        justify-content: flex-start;
        margin-top: 5%;
    }

    .card{
        opacity: 0;
        width: 60%;
        height: 90%;
        border-radius: 10px;
        box-shadow: 0 18px 28px 0 rgba(0, 0, 0, 0.3);
        border: solid 1px #848484;
        background-color: #ffffff;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    .card_p1{
        width: 73%;
        font-size: 2vw;
        font-family: 'Open Sans', sans-serif;
        font-weight: 300;
        color: #757575;
        text-align: center;
        line-height: 1.3;
        margin-top: 3%;
    }
    
    .card_p2{
        font-size: 2vw;
        font-family: 'Open Sans', sans-serif;
        font-weight: 600;
        color: #414141;
        text-align: center;
        margin-top: 3%;
        line-height: 1.3;
        margin-top: 10%;
    }


    .button_container{
        width: 80%;
        height: 15vh;
        /* height: 20vh; */
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        margin-top: 8%;
        margin-bottom: 5%;
        text-align: center;
    }


    .options_container{
        height: 100%;
        width: 100%;
    }
    
    .options_container p{
        margin: 0 !important;
        width: 100%;
        font-family: 'Open Sans';
        font-size: 1.2rem;
        font-weight: 500;
    }

    .button{
        width: 50%;
        font-size: 1.5vw;
    }
    
    .expP{
        font-size: 1.9vw;
    }
}


@media only screen and (min-width: 1000px) and (max-width: 1200px) and (orientation: landscape){

    /* .logIMG{
        width: 300px;
    } */

    .log{
        height: 28vh;
    }
    
  }
