
/* ANIMATION BARBA */
.load_container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    z-index: 1000;
    pointer-events: none;
    z-index: 2000;
    padding-top: 56.25%; 
  }

  .load_screen {
    position: relative;
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
    background-color: #27385f;
    width: 0%;
    opacity: 0;
    height: 100vh;
    z-index: 2000;
  }
