
.bg{
  /* background-color: #121d37; */
  height: 100svh;
  width: 100svw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}

.container_close{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
}

.close{
  width: 90%;
  position: absolute;
  top: 11%;
  right: -73%;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  right: -9%;
  top: -7%;
}

.close_img{
  width: 25%;
}

.card_canvas{
  opacity: 0;
  width: 100vw;
  height: 101vh;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background-color: #311864; */
}

.container{
  position: relative;
  border-radius: 12px;
  width: 93%;
  height: 65%;
  margin-top: -10%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  box-shadow: 0 18px 31px 0 rgba(0, 0, 0, 0.3);
  background-color: #fff;
  min-width: 300px;
  max-width: 400px;
  min-height: 530px;
  max-height: 600px;
}

.title_canvas{
  width: 100%;
  margin-top: 6%;
  height: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title_p{
  font-family: 'Open Sans', sans-serif;
  font-size: 17px;
  font-weight: 300;
  color: #121d37;
  margin-top: 3%;
  font-weight: 700;
  width: 89%;
  margin-right: -3%;
}

.title_p2{
  font-family: 'Open Sans', sans-serif;
  font-size: 17px;
  font-weight: 600;
  color: #121d37;
  margin-top: 2px;
}

.canvas{
  border-radius: 12px;
  box-shadow: inset 6px 6px 20px 15px rgb(0 0 0 / 30%);
  background-color: #311864;
  position: relative;
}

.buttons_container{
  width: fit-content;
  height: 90px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  align-items: center;
  position: absolute;
  left: 50%;
  bottom: -10%;
  transform: translate(-50%, 0px);
}

.hand_buttons{
  align-items: center;
  justify-content: center;
  width: 96%;
  left: 3%;
  height: 65px;
  display: flex;
  left: 5%;
  position: absolute;
  margin-bottom: 3%;
  z-index: 100;
  border: solid 1px #dedede;
  background-color: rgba(255, 255, 255, 0.98);
  border-radius: 4px;
  box-shadow: 0 8px 17px 0 rgba(14, 9, 33, 0.1);
}

/* ::-webkit-scrollbar{
height: 8px;
width: 4px;
padding-top: 20px;
background: rgb(29, 47, 70);
border-radius: 30px;
} */

::-webkit-scrollbar-thumb:horizontal{
background: rgb(48, 176, 220);
border-radius: 10px;
border-radius: 30px;
}

.select_button{
  background: none;
  border: 0;
  cursor: pointer;
  text-decoration: none;
  margin: 1%;
}

.select_button:focus-within{
  background: none;
  border: 0;
  cursor: pointer;
  text-decoration: none;
}

.button-wrapper {
position: relative;
width: 150px;
text-align: center;
margin: 20% auto;
}

.button_wrapper span.label {
position: relative;
z-index: 0;
display: inline-block;
width: 100%;
background: #E73493;
cursor: pointer;
color: #fff;
padding: 10px 0;
text-transform:uppercase;
font-size:12px;
}

#input_draw {
border-radius: 8px;
height: 4px;
background-color: #AF1C86;
width: 150px;
outline: none;
-webkit-appearance: none !important; 
}

input[type=range] {
height: 45px;
-webkit-appearance: none;
margin: 10px 0;
width: 100%;
}
input[type=range]:focus {
outline: none;
border: none;
box-shadow: none;
}
input[type=range]::-webkit-slider-runnable-track {
width: 100%;
height: 13px;
cursor: pointer;
animate: 0.2s;
box-shadow: 1px 1px 1px #F7F7F7;
background: #DFDFDF;
border-radius: 14px;
border: 1px solid #FFFFFF;
}
input[type=range]::-webkit-slider-thumb {
box-shadow: 1px 1px 1px #FFFFFF;
border: 4px solid #F7F7F7;
height: 34px;
width: 34px;
border-radius: 33px;
background: #AF1C86;
cursor: pointer;
-webkit-appearance: none;
margin-top: -13px;
}
input[type=range]:focus::-webkit-slider-runnable-track {
background: #DFDFDF;
}
input[type=range]::-moz-range-track {
width: 100%;
height: 13px;
cursor: pointer;
animate: 0.2s;
box-shadow: 1px 1px 1px #F7F7F7;
background: #DFDFDF;
border-radius: 14px;
border: 1px solid #FFFFFF;
}
input[type=range]::-moz-range-thumb {
box-shadow: 1px 1px 1px #FFFFFF;
border: 4px solid #F7F7F7;
height: 34px;
width: 34px;
border-radius: 33px;
background: #AF1C86;
cursor: pointer;
}
input[type=range]::-ms-track {
width: 100%;
height: 13px;
cursor: pointer;
animate: 0.2s;
background: transparent;
border-color: transparent;
color: transparent;
}
input[type=range]::-ms-fill-lower {
background: #DFDFDF;
border: 1px solid #FFFFFF;
border-radius: 28px;
box-shadow: 1px 1px 1px #F7F7F7;
}
input[type=range]::-ms-fill-upper {
background: #DFDFDF;
border: 1px solid #FFFFFF;
border-radius: 28px;
box-shadow: 1px 1px 1px #F7F7F7;
}
input[type=range]::-ms-thumb {
margin-top: 1px;
box-shadow: 1px 1px 1px #FFFFFF;
border: 4px solid #F7F7F7;
height: 34px;
width: 34px;
border-radius: 33px;
background: #AF1C86;
cursor: pointer;
}
input[type=range]:focus::-ms-fill-lower {
background: #DFDFDF;
}
input[type=range]:focus::-ms-fill-upper {
background: #DFDFDF;
}


.menu_draw{
  position: absolute;
  background: rgb(196, 183, 183);
  display: flex;
  align-items: center;
  left: 4vw;
  top: 8vh;
  border-radius: 12px;
  box-shadow: 0 12px 32px 8px rgba(7, 6, 34, 0.47);
  background-color: #fff;
  width: fit-content;
  padding: 2px 11px;
}

.drawing_p_done{
color: #af1c86;
font-size: 0.7rem;
}

.drawing_p_undo{
color: #878787;
font-size: 0.7rem;
}

.draw_selector{
text-align: center;
}

.drawing_line{
width: 100%;
color: #878787;
font-size: 0.8rem;
font-size: 'Open sans';
}

.menu_background{
  position: absolute;
  background: rgb(196, 183, 183);
  display: block;
  align-items: center;
  left: 4vw;
  top: 4vh;
  border-radius: 12px;
  box-shadow: 0 12px 32px 8px rgba(7, 6, 34, 0.47);
  background-color: #fff;
  width: fit-content;
  height: fit-content;
  padding: 10px;
}

.bg_row1{
display: flex;
}

.bg_row2{
display: flex;
}

.bg_square, .bg_square2, .bg_square3, .bg_square4, .bg_square5, .bg_square6, .bg_square7, .bg_square8 {
  height: 45px;
  width: 45px;
  margin: 5px;
  border-radius: 9px;
  cursor: pointer;
  text-decoration: none;
  border: 0;
}

.bg_square{
background-color: #af1c86;
}

.bg_square2{
background-color: #e8349e;
}

.bg_square3{
background-color: #e8345d;
}

.bg_square4{
background-color: #f03535;
}

.bg_square5{
background-color: #ec7b3a;
}

.bg_square6{
background-color: #db5ed5;
}

.bg_square7{
background-color: #9550ae;
}

.bg_square8{
background-color: #541c56;
}


select{
outline: none;
}

select:active{
outline: none;
}

.select_img{
width: 36px
}

.select_trash{
width: 36px;
}

.select_undo{
width: 15px !important;
margin: 3px !important;
}

.select_redo{
  width: 18px;
  margin: 3px;
}

.selector{
width: 22px !important;
margin: 3px !important;
}

.upload{
width: 36px;
}

.select_text{
height: 4.3vh;
border-radius: 1rem;
top: 14px;
}

.select_text:after {
position: absolute;
content: "";
top: 14px;
right: 10px;
width: 0;
height: 0;
border: 6px solid transparent;
border-color: #fff transparent transparent transparent;
}

/*style the items (options), including the selected item:*/
.select_text div,.select-selected {
color: #ffffff;
padding: 8px 16px;
border: 1px solid transparent;
border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
cursor: pointer;
user-select: none;
}




.optionsDiv{
background-color: red;
display: flex;
justify-content: center;
position: absolute;
width: 10vw;
left: 4vw;
top: 18.5vh;
border-radius: 15px;
border: solid 1px #dedede;
background-color: rgba(255, 255, 255, 0.97);
}

.select_row{
  color: #38577e;
}

.select_brush{
  color: #38577e;
}

.select_eraser{
  color: #38577e;
}

.select_trash{
  color: #38577e;
}

.info{
position: sticky;
left: 71.5%;
cursor: pointer;
}

.send_button{
  border-radius: 30px;
  background-color: #C68A12;;
  border:0px;
  font-size: 15px;
  font-family: "Poppins";
  font-weight: 600;
  color: #ffffff;
  cursor: pointer;
  width: 220px;
  height: 60px;
}

.send_button:hover{
  background-color: #9B6C0D;
  /* color:white; */
}

.button_back{
  border-radius: 28.5px;
  background-color:#ffffff;
  border: 5px solid #ffffff;
  font-size: 15px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 500;
  color: #777;
  cursor: pointer;
  width: 200px;
  height: 5vh;
  align-items: center;
  display: flex;
}

.angle_left{
  width: 6px;
  height: 10px;
  margin: 5px 5px 6px 0;
  opacity: 0.89;
  cursor: pointer;
}

.send_plane{
  padding-left: 7%;
}

canvas{
  width: 100%;
}

.empty_container{
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 0%;
  margin-bottom: -3%;
}

.empty_p{
  width: 100%;
  color: #c92745;
  font-family: 'Open Sans', sans-serif;
  font-size: 0.9vw;
}

/* POPUP */
.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color:rgba(0, 0, 0, 0.5);
  z-index: 9001;
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup_inner {
  width: 30% ;
  height: 50%;
  background: #fdfdfd;
  text-align: center;
  box-shadow: 0 18px 31px 0 rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.container_popup{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.div_img_popup{
  width: 100%;
  display: flex;
  justify-content: center;
}

.img_popup{
  width: 55%;
  }

.videoE{
  width: 180px;
}

.p_popup{
  color: #4D4D4D;
  width: 70%;
  font-family: 'Open Sans', sans-serif;
  font-size: 1.1vw;
  font-weight: 300;
}

.pw_popup{
  font-weight: 500;
}

.button_popup{
  font-family: 'Open Sans', sans-serif;
  font-size: 1vw;
  color: #c92745;
  font-weight: 500;
  cursor: pointer;
  border: none;
  background: none;
}



/* POPUP VIDEO */
.popupV {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color:rgba(0, 0, 0, 0.5);
  z-index: 9001;
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup_innerV {
  width: 25%;
  height: 48%;
  background: #fdfdfd;
  text-align: center;
  box-shadow: 0 18px 31px 0 rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.container_popupV{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.pw_popupV{
  color: #4D4D4D;
}

.div_img_popupV{
  width: 100%;
  display: flex;
  justify-content: center;
}

.img_popupV{
  width: 40%;
  }

.videoE{
  width: 180px;
}

.p_popupV{
  color: #4D4D4D;
  width: 70%;
  font-family: 'Open Sans', sans-serif;
  font-size: 1.1vw;
  font-weight: 300;
}

.div_img_popupS{
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
}

.img_popupS{
  height: 20vh;
  margin-top: -12%;
}

.button_popupV{
    font-family: 'Open Sans', sans-serif;
    font-size: 1vw;
    color: #4f4f4f;
    background-color: #f2f2f2;
    font-weight: 500;
    cursor: pointer;
    border: none;
    width: 125px;
    border-radius: 28.5px;
}


/* TRASH */

.popupT {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color:rgba(0, 0, 0, 0.5);
  z-index: 9001;
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup_innerT {
  width: 30% ;
  height: 25%;
  background: #fbfbfb;
  text-align: center;
  box-shadow: 0 18px 31px 0 rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.container_popupT{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.container_popupT{
  align-items: center;
  display: flex;
}

.div_img_popupT{
  width: 100%;
  display: contents;
  justify-content: space-around;
}

.img_popupT{
  width: 40%;
  }

.p_popupT{
  color: #101333;
  width: 60%;
  font-family: 'Open Sans', sans-serif;
  font-size: 1.3vw;
  font-weight: 400;
  margin-top: 2rem;
}

.pw_popupT{
  font-weight: 500;
}

.button_popupT{
  border-radius: 28.5px;
  box-shadow: 0 18px 28px 0 rgba(0, 0, 0, 0.3);
  background-color: #e83493;
  border: 5px solid  #e83493;
  font-size: 15px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  color: #fff;
  cursor: pointer;
  width: 125px;
  height: 4.5vh;
}


.div_img_popupD{
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}

.div_warning_popup{
  background-color: #f7f7f7;
  position: absolute;
  left: 40%;
  top: -18%;
  padding: 15px;
  border-radius: 55px;
  width: fit-content;
  box-shadow: 0 5px 27px 0 rgba(15, 7, 44, 0.12);
}

.log{
width: 100%;
position: absolute;
}

.log_divs{
  display: flex;
  justify-content: center;
}

.logIMG{
  width: 20%;
  padding-top: 2%;
}


@media only screen and (max-width: 600px){

  .container{
    margin-top: -35%;
  }


  .hand_buttons{
    align-items: center;
    justify-content: center;
    width: 100%;
    left: 0%;
    display: flex;
    /* top: 51%; */
  }

  
}

@media only screen and (min-width: 700px) and (max-width: 900px) and (orientation: portrait){

  .popup_inner{
      width: 50%;
  }

  .p_popup{
      font-size: 2.3vw;
      margin-top: 5%;
  }

  .img_popup{
      width: 70%;
      height: 50%;
  }

  .container{
    width: 65%;
    height: 70%;
  }

  .close{
      top: 5vh;
      right: -61vw;
  }

  .close_img{
    width: 20%;
  }

  .buttons_container{
    top: inherit;
    bottom: 135px;
  }

  .hand_buttons{
    align-items: center;
    justify-content: center;
    width: 66%;
    left: 17%;
    display: flex;
    top: 58%;
  }

  /* SEND IMAGE */

  .popup_innerV {
      width: 50% ;
      height: 50%;
    }

  .videoE{
      width: 150px;
  }
  
  .p_popupV{
      width: 90%;
      font-size: 2.5vw;
  }

  .button_popupV{
    font-size: 2.1vw;
  }

  .div_img_popupV{
      align-items: center;
  }

  .info{
    position: sticky;
    left: 90.5%;
    cursor: pointer;
  }

  /* TRASH */

  .popup_innerT {
      width: 100% ;
      height: 100%;
    }

  .p_popupT{
      width: 100%;
      font-size: 2.1vw;
  }

}



@media only screen and (min-width: 1000px) and (max-width: 1200px) and (orientation: landscape){

  .container{
    width: 520px;
    height: 85%;
    margin-top: 0;
  }

  .hand_buttons{
    width: 320px;
    height: 45px;
    left: 34%;
    top: 70%;
  }
  
  .buttons_container{
    top: 80%;
  }

  .hand_buttons button img{
    margin: 0 9px 0;
  }

  .hand_buttons button p{
    margin: auto;
  }

  .close{
    width: 350px;
    top: 10px;
    right: -5px;
  }

}


/* TOOLTIP */

.select_button {
  position: relative;
  display: inline;
}

.select_button img{
  width: 36px;
  margin: 0 9px 6px;
}

.select_button span {
  visibility: hidden;
  width: fit-content;
  color: white;
  text-align: center;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  top: 0px;
  left: 60px;
  opacity: 0;
  transition: opacity 0.3s;
  color: #4c4c4c;

  padding: 10px 13px 8px 14px;
  border-radius: 18.5px;
  background-color: #ebebeb;
}

.select_button:hover span {
  visibility: visible;
  opacity: 1;
}