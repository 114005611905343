
.general_container{
    height: 100vh;
    width: 100vw;
}

.bg{
    background-color: rgba(28, 45, 86, 0.98);
    z-index: 9;
    height: 101vh;
    width: 100vw;
    position: absolute;
    top: 0;
    left: 0;
}

.swip_logo{
    width: 23%;
    padding-bottom: 1%;
    position: absolute;
    top: 17%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    z-index: 9000;
}

.swip_progressbar{
    width: 33.3%;
    height: 30%;
    padding-bottom: 1%;
    position: absolute;
    top: 21.4%;
    left: 96%;
    transform: translate(-50%, -50%);
    text-align: center;
    z-index: 9000;
}

.swip_hand{
    width: 55px;
    padding-bottom: 1%;
    transform: translate(-50%, -50%) !important;
    text-align: center;
    opacity: 0;
}

.swip_container{
    opacity: 0;
    bottom: 20%;
    left: 50%;
    position: absolute;
}

.p_container{
    opacity: 0;
    width: 100vw;
    position: absolute;
    bottom: 19%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.swip_p{
    /* opacity: 0; */
    width: 100%;
    font-family: 'Open Sans', sans-serif;
    color: #FFFFFF;
    font-size: 18px;
    font-weight: 500;
    opacity: 0;
}

.button_container{
    opacity: 1;
    width: 280px;
    height: 50px;
    position: absolute;
    bottom: 7vh;
    left: 50vw;
    transform: translate(-50%, -50%);
    text-align: center;
}

.button{
    opacity: 1;
    width: 15%;
    height: 50%;
    font-family: 'Open Sans', sans-serif;
    /* font-size: 0.9vw; */
    border-radius: 28.5px;
    box-shadow: 0 18px 28px 0 rgba(0, 0, 0, 0.3);
    background-color: #E73493;
    color: #ffffff;
    border: 5px solid #E73493;
    cursor: pointer;
}

.buttonContainerFoot{
    /* opacity: 0; */
    width: 100%;
    height: 100%;
    font-family: 'Poppins';
    font-size: 16px;
    font-weight: 400;
    border-radius: 28px;
    box-shadow: 0 5px 26px 0 rgba(11, 9, 33, 0.49);
    background-color: #C68A12;
    color: #ffffff;
    border: none;
    cursor: pointer;
    text-align: center;
    opacity: 0;
}

.buttonContainerFoot:hover{
    background-color: #9B6C0D;
    /* color: #C68A12; */
}

.buttonMode{
    font-size: 0.7vw;
    border-radius: 10px;
    box-shadow: 0 18px 28px 0 rgba(0, 0, 0, 0.3);
    background-color: #E73493;
    border: 5px solid  #E73493;
    height: 5.5vh;
    top: 8rem;
    right: -3.5vw;
    position: absolute;
    cursor: pointer;
    color: #ffffff;
}


button:focus {outline:0;}

.send_pencil{
    padding-left: 7%;
}

.swip_footer{
    padding: 0;
    position: fixed;
    bottom: 1vh;
    left: 50vw;
    transform: translate(-50%, -50%);
    z-index: 9004;
}

.last{
    background-color: red;
}


.send_plane{
    margin: 0px 0px 0px 8px;
}


/* Media queries */
@media only screen and (max-width: 600px) {

    .swip_container{
        bottom: 32%; 
    }

    .p_container{
        bottom: 27%;
    }

    .button_container{
        height: 55px;
        bottom: 15vh;
    }

}
