.rotate_phone_please {
  position: absolute;
  top: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #fff;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.8);
  font-family: 'Roboto', sans-serif; 
}
  
  .phone {
    display: inline-block;
    width: 30px;
    height: 60px;
    border: 3px solid #fff;
    border-radius: 5px;
    position: relative;
  }
  
  .phone:after {
    content:'';
    width: 100%;
    border-bottom: 3px solid #fff;
    position: absolute;
    top: 4px;
    left: 0;
  }
  
  .phone:before {
    content:'';
    width: 100%;
    border-bottom: 3px solid #fff;
    position: absolute;
    bottom: 6px;
    left: 0;
  }
  
  .phone {
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-name: anim-rotate;
    animation-duration: 2s;
  }


  .message{
    margin: 30px;
  }
  
  @keyframes anim-rotate {
    0%, 10% { transform: rotate(0deg) }
    40%, 60% { transform: rotate(90deg) }
    95%, 100% { transform: rotate(0deg) }
  }
  