
.bg{
  /* background-color: #121d37; */
  height: 101vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
}

.container_close{
  width: 750px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.close{
  width: 90%;
  position: relative;
  top: 50%;
  right: 23.5%;
  cursor: pointer;
}

.close_img{
  width: 6%;
}

.card_canvas{
  opacity: 0;
  width: 100vw;
  height: 101vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.container{
  /* opacity: 0; */
  position: relative;
  min-width: 750px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0 18px 31px 0 rgba(0, 0, 0, 0.3);
  background-color: #fff;
}

.title_canvas{
  width: 100%;
  height: 90px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title_p{
  font-family: 'Open Sans', sans-serif;
  font-size: 17px;
  font-weight: 300;
  color: #121d37;
  margin-top: 1%;
  font-weight: 700;
  width: 89%;
}

.title_p2{
  font-family: "Poppins";
  font-size: 17px;
  font-weight: 600;
  color: #121d37;
  margin-top: 2px;
}

.title_p2 span{
  color: #C68A12;
}

.canvas{
  box-shadow: inset 6px 6px 20px 15px rgb(0 0 0 / 30%);
  background-color: #311864;
}

.buttons_container{
  width: 85%;
  height: 90px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  align-items: center;
}

.button_back p{
  width: 100%;
}

.hand_buttons{
  align-items: center;
  justify-content: center;
  left: 665px;
  top: 150px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  position: absolute;
  z-index: 100;
  border: solid 1px #dedede;
  background-color: rgba(255, 255, 255, 0.98);
  border-radius: 4px;
  box-shadow: 0 8px 17px 0 rgba(14, 9, 33, 0.2);
}

/* ::-webkit-scrollbar{
height: 8px;
width: 4px;
padding-top: 20px;
background: rgb(29, 47, 70);
border-radius: 30px;
} */

::-webkit-scrollbar-thumb:horizontal{
background: rgb(48, 176, 220);
border-radius: 10px;
border-radius: 30px;
}

.select_button{
  background: none;
  border: 0;
  cursor: pointer;
  text-decoration: none;
  margin: 1%;
  padding: 5px;
}

.select_button:focus-within{
  background: none;
  border: 0;
  cursor: pointer;
  text-decoration: none;
}

.button-wrapper {
position: relative;
width: 150px;
text-align: center;
margin: 20% auto;
}

.button_wrapper span.label {
position: relative;
z-index: 0;
display: inline-block;
width: 100%;
background: #E73493;
cursor: pointer;
color: #fff;
padding: 10px 0;
text-transform:uppercase;
font-size:12px;
}

#input_draw {
border-radius: 8px;
height: 0px;
width: 150px;
outline: none;
-webkit-appearance: none !important; 
}

input[type=range] {
height: 45px;
-webkit-appearance: none;
margin: 10px 0;
width: 100%;
}
input[type=range]:focus {
outline: none;
border: none;
box-shadow: none;
}
input[type=range]::-webkit-slider-runnable-track {
width: 100%;
height: 13px;
cursor: pointer;
animate: 0.2s;
box-shadow: 1px 1px 1px #F7F7F7;
background: #DFDFDF;
border-radius: 14px;
border: 1px solid #FFFFFF;
}
input[type=range]::-webkit-slider-thumb {
box-shadow: 1px 1px 1px #FFFFFF;
border: 4px solid #F7F7F7;
height: 34px;
width: 34px;
border-radius: 33px;
background: #042c24fa !important;
cursor: pointer;
-webkit-appearance: none;
margin-top: -13px;
}
input[type=range]:focus::-webkit-slider-runnable-track {
background: #DFDFDF;
}
input[type=range]::-moz-range-track {
width: 100%;
height: 13px;
cursor: pointer;
animate: 0.2s;
box-shadow: 1px 1px 1px #F7F7F7;
background: #DFDFDF;
border-radius: 14px;
border: 1px solid #FFFFFF;
}
input[type=range]::-moz-range-thumb {
box-shadow: 1px 1px 1px #FFFFFF;
border: 4px solid #F7F7F7;
height: 34px;
width: 34px;
border-radius: 33px;
background: #042c24fa !important;
cursor: pointer;
}
input[type=range]::-ms-track {
width: 100%;
height: 13px;
cursor: pointer;
animate: 0.2s;
background: transparent;
border-color: transparent;
color: transparent;
}
input[type=range]::-ms-fill-lower {
background: #DFDFDF;
border: 1px solid #FFFFFF;
border-radius: 28px;
box-shadow: 1px 1px 1px #F7F7F7;
}
input[type=range]::-ms-fill-upper {
background: #DFDFDF;
border: 1px solid #FFFFFF;
border-radius: 28px;
box-shadow: 1px 1px 1px #F7F7F7;
}
input[type=range]::-ms-thumb {
margin-top: 1px;
box-shadow: 1px 1px 1px #FFFFFF;
border: 4px solid #F7F7F7;
height: 34px;
width: 34px;
border-radius: 33px;
background: #042c24fa !important;
cursor: pointer;
}
input[type=range]:focus::-ms-fill-lower {
background: #DFDFDF;
}
input[type=range]:focus::-ms-fill-upper {
background: #DFDFDF;
}



.menu_draw{
  position: absolute;
  background: rgb(196, 183, 183);
  display: flex;
  align-items: center;
  left: 115%;
  top: 30%;
  border-radius: 12px;
  box-shadow: 0 12px 32px 8px rgba(7, 6, 34, 0.47);
  background-color: #fff;
  width: fit-content;
  padding: 2px 11px;
  z-index: 100;
}

.drawing_p_done{
color: #042c24fa;
font-size: 0.7rem;
}

.drawing_p_undo{
color: #878787;
font-size: 0.7rem;
}

.draw_selector{
text-align: center;
}

.drawing_line{
width: 100%;
color: #878787;
font-size: 0.8rem;
font-family: 'Open sans';
padding-top: 6px;
}

#menuBg{
  opacity: 0;
}


.menu_background{
  position: absolute;
  background: rgb(196, 183, 183);
  display: block;
  align-items: center;
  left: 115%;
  top: 2%;
  border-radius: 12px;
  box-shadow: 0 12px 32px 8px rgba(7, 6, 34, 0.47);
  background-color: #fff;
  width: fit-content;
  height: fit-content;
  padding: 10px;
}


.menu_background_responsive{
transform: translate(-50%, 0);
left: 50%;
position: absolute;
background: rgb(196, 183, 183);
display: flex;
align-items: center;
top: 9vh;
border-radius: 12px;
box-shadow: 0 12px 32px 8px rgba(7, 6, 34, 0.47);
background-color: #fff;
width: 60%;
overflow: scroll;
height: fit-content;
padding: 10px;
}

.bg_row1{
display: flex;
}

.bg_row1_responsive{
display: inline-flex;
}

.bg_row2{
display: flex;
}

.bg_square, .bg_square2, .bg_square3, .bg_square4, .bg_square5, .bg_square6, .bg_square7, .bg_square8 {
height: 45px;
width: 45px;
margin: 5px;
border-radius: 9px;
cursor: pointer;
text-decoration: none;
border: 0;
}

.bg_square{
background-color: #af1c86;
}

.bg_square2{
background-color: #e8349e;
}

.bg_square3{
background-color: #e8345d;
}

.bg_square4{
background-color: #f03535;
}

.bg_square5{
background-color: #ec7b3a;
}

.bg_square6{
background-color: #db5ed5;
}

.bg_square7{
background-color: #9550ae;
}

.bg_square8{
background-color: #541c56;
}


select{
outline: none;
}

select:active{
outline: none;
}

.select_img{
width: 26px
}

.select_trash{
width: 26px;
}

.select_undo{
width: 15px !important;
margin: 3px !important;
}

.select_redo{
  width: 18px;
  margin: 3px;
}

.selector{
width: 26px !important;
margin: 5px 0px !important;
}

.upload{
width: 26px;
}

.select_text{
width: 100%;
height: 45px;
color: #323232;
font-size: 15px;
border: none;
top: 14px;
background: transparent;
padding-left: 20px;
-webkit-appearance: none;
-moz-appearance: none;
appearance: none;
cursor: pointer;
}


select:after {
position: absolute;
content: "";
top: 14px;
right: 10px;
width: 0;
height: 0;
border: 6px solid transparent;
border-color: #fff transparent transparent transparent;
}

/*style the items (options), including the selected item:*/
.select_text div,.select-selected {
color: #ffffff;
padding: 8px 16px;
border: 1px solid transparent;
border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
cursor: pointer;
user-select: none;
}

.optionsDiv{
display: flex;
justify-content: center;
position: absolute;
width: 13vw;
left: 115%;
top: 60%;
color: #042c24fa ;
border-radius: 30px;
border: solid 1px #dedede;
background-color: rgba(255, 255, 255, 0.97);
padding: 6px;
cursor: pointer;
}

.optionsDiv2{
  display: flex;
  justify-content: center;
  position: absolute;
  width: 25vw;
  left: 115%;
  top: -67%;
  color: #042c24fa ;
  border-radius: 30px;
  border: solid 1px #dedede;
  background-color: rgba(255, 255, 255, 0.97);
  padding: 6px;
  cursor: pointer;
  }

  .optionsDiv2_selector{
    width: 190vw;
    margin: 0 auto;
    padding: 0 auto;
    border-radius: 12px;
  }

.optionsDiv::after {
  content: '⌄';
  position: absolute;
  font-size: 22px;
  background: transparent;
  right: 10%;
  top: 1vh;
}

.optionsDiv2_selector::after{
  content: '⌄';
  position: absolute;
  font-size: 22px;
  background: transparent;
  right: 40%;
  top: 1vh;
  }


.select_row{
  color: #38577e;
}

.select_brush{
  color: #38577e;
}

.select_eraser{
  color: #38577e;
}

.select_trash{
  color: #38577e;
}

.info{
position: sticky;
left: 71.5%;
cursor: pointer;
}

.send_button{
  border-radius: 30px;
  color: #fff;
  background-color: #C68A12;
  border:0px;
  font-size: 15px;
  font-family: "Poppins";
  font-weight: 500;
  cursor: pointer;
  width: 220px;
  height: 50px;
}


.send_button:hover{
  background-color: #9B6C0D;
  /* color: #C68A12; */
}



.button_back{
  border-radius: 30px;
  background-color:#f2f2f2;
  border: 2px solid #f2f2f2;
  font-size: 15px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 500;
  color: #6d6d6d;
  cursor: pointer;
  width: 100px;
  height: 50px;
  
}

.angle_left{
  width: 6px;
  height: 10px;
  margin: 5px 5px 6px 0;
  opacity: 0.89;
  cursor: pointer;
}

.send_plane{
  padding-left: 7%;
}

canvas{
  border: 1px solid #fff !important;
  /* background-color: #2a005be6;
  border-radius: 12px; */
}

.empty_container{
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 2%;
}

.empty_p{
  width: 100%;
  color: #c92745;
  font-family: 'Open Sans', sans-serif;
  font-size: 0.9vw;
}

/* POPUP */
.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color:rgba(14, 13, 43, 0.8);
  z-index: 9001;
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup_inner {
  width: 30% ;
  height: 50%;
  background: #fdfdfd;
  text-align: center;
  box-shadow: 0 18px 31px 0 rgba(14, 13, 43, 0.8);
  border-radius: 10px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.container_popup{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.div_img_popup{
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.img_popup{
  width: 55%;
}

.videoE{
  width: 180px;
}

.p_popup{
  color: #4D4D4D;
  width: 70%;
  font-family: 'Open Sans', sans-serif;
  font-size: 1.1vw;
  font-weight: 300;
}

.pw_popup{
  font-weight: 500;
}

.button_popup{
    font-family: 'Open Sans', sans-serif;
    font-size: 1vw;
    color: #c92745;
    font-weight: 500;
    cursor: pointer;
    border: none;
    background: none;
}

/* POPUP VIDEO */
.popupV {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color:rgba(0, 0, 0, 0.5);
  z-index: 9001;
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup_innerV {
  width: 25%;
  height: 48%;
  background: #fdfdfd;
  text-align: center;
  box-shadow: 0 18px 31px 0 rgba(14, 13, 43, 0.5);
  border-radius: 10px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.container_popupV{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.pw_popupV{
  color: #4D4D4D;
}

.div_img_popupV{
  width: 100%;
  display: flex;
  justify-content: center;
}

.img_popupV{
  width: 40%;
}

.videoE{
  width: 180px;
}

.p_popupV{
  color: #4D4D4D;
  width: 70%;
  font-family: 'Open Sans', sans-serif;
  font-size: 1.1vw;
  font-weight: 300;
}

.div_img_popupS{
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
}

.img_popupS{
  height: 20vh;
  margin-top: -12%;
}

.button_popupV{
  font-family: "Poppins";
  font-size: 16px;
  color: #071d49;
  background-color: #f2f2f2;
  font-weight: 500;
  cursor: pointer;
  border: none;
  border-radius: 30px;
  height: 4.5vh;
  width: 165px;
}


.button_popupV2{
  font-family: 'Poppins';
  font-size: 16px;
  text-transform: uppercase;
  color: #071d49;
  font-weight: 600;
  cursor: pointer;
  border: none;
  padding: 15px 25px;
  border-radius: 30px;
  background-color: #f2f2f2;
}
  
.button_popupV3{
  display: none;
  font-family: 'Poppins';
  font-size: 16px;
  text-transform: uppercase;
  color: #ffffff;
  font-weight: 600;
  cursor: pointer;
  border: none;
  padding: 15px 25px;
  border-radius: 30px;
  background-color: #C68A12;
}

/* .button_popupV3:hover{
  background-color: #6A2EAE;
  color:white;
} */


/* TRASH */

.popupT {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9001;
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup_innerT {
  width: 330px ;
  height: 270px;
  background: #fbfbfb;
  text-align: center;
  box-shadow: 0 18px 31px 0 rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.container_popupT{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.container_popupT{
  align-items: center;
  display: flex;
}

.div_img_popupT{
  width: 100%;
  display: contents;
  justify-content: space-around;
}

.img_popupT{
  width: 40%;
}

.p_popupT{
  color: #4b4d5b;
  width: 80%;
  font-family: "Poppins";
  font-size: 12px;
  font-weight: 400;
}

.p_popupT2{
  font-family: "Poppins";
  color: #101333;
  width: 80%;
  font-size: 14px;
  font-weight: 600;
  margin-top: 30px;
  margin-bottom: 0px;
}

.p_popupT3{
  font-family: "Poppins";
  color: #101333;
  width: 80%;
  font-size: 16px;
  font-weight: 600;
  margin-top: 30px;
  margin-bottom: 0px;
  display: none;
}

.pw_popupT{
  font-weight: 500;
}

.button_popupT{
  border-radius: 30px;

  background-color: #C68A12;
  border:0px;

  font-size: 15px;
  font-family: "Poppins";
  font-weight: 600;
  color: #fff;
  cursor: pointer;
  width: 165px;
  height: 4.5vh;
  margin-bottom: 1vh;
}

.button_popupT:hover{
  background-color: #9B6C0D;
  /* color: #00a3df; */
}


.div_img_popupD{
  width: 100%;
  flex-flow: column;
  display: flex;
  align-items: center;
}

.div_warning_popup{
  background-color: #f7f7f7;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -60%);
  padding: 15px;
  border-radius: 55px;
  width: fit-content;
  box-shadow: 0 5px 27px 0 rgba(15, 7, 44, 0.12);
}

.div_warning_popup img{
  text-align: center;
  display: block;
}

.log{
width: 100%;
position: absolute;
}

.log_divs{
  display: flex;
  justify-content: center;
}

.logIMG{
  width: 20%;
  padding-top: 2%;
}


.p_description{
  color: #878787;
  font-size: 10px;
  margin: auto;
}




@media only screen and (max-width: 600px){
.select_img{
  width: 28px !important;
  margin: 0px 0px 12px !important;
}

/* .selector{
  margin: 0px 0px 12px !important;
} */

.select_trash{
  width: 28px !important;
}

.upload{
  width: 28px !important;
  margin: 0px 0px 10px !important;
}

.menu_draw{
  left: 50%;
  transform: translate(-50%, 50px);
}

.optionsDiv{
  transform: translate(-50%, 0);
  left: 50%;
  width: 60%;
  top: 0vh;
}

.optionsDiv2{
  transform: translate(-50%, 0);
  left: 50%;
  width: 80%;
  top: 0vh;
}

.popup_innerT {
  width: 90%;
  height: 25%;
}

.p_popupT{
  font-size: 12px;
}

.popup_inner {
  width: 80%;
  height: 30%;
}

.p_popup{
  font-size: 16px;
}

  .title_p{
    font-size: 16px;
    margin-top: 3%;
    margin-right: -3%;
    width: 75%;
  }

  .title_p2{
    font-size: 13px;
    /* margin-top: 20px; */
  }

  .p_description{
    position: absolute;
    bottom: 7px;
    width: 100%;
  }


  .title_canvas{
    margin-bottom: 10px !important;
  }

}


/* Ipad */
@media only screen and (min-width: 700px) and (max-width: 900px) and (orientation: portrait){


  .menu_draw{
    left: 27%;
    top:9vh;
  }

  .optionsDiv{
    transform: translate(-50%, 0);
    left: 50%;
    width: 190px;
    top: 9vh;;
  }


  .popup_inner{
      width: 50%;
  }

  .p_popup{
      font-size: 2.3vw;
      margin-top: 5%;
  }

  .img_popup{
      width: 70%;
      height: 50%;
  }

  .close{
      top: 2vh;
      right: 6vw;
  }

  /* SEND IMAGE */

  .popup_innerV {
      width: 50% ;
      height: 50%;
    }

  .videoE{
      width: 150px;
  }
  
  .p_popupV{
      width: 90%;
      font-size: 2.5vw;
  }

  .button_popupV{
    font-size: 2.1vw;
  }

  .div_img_popupV{
      align-items: center;
  }

  .info{
    position: sticky;
    left: 90.5%;
    cursor: pointer;
  }

  /* TRASH */

  /* .popup_innerT {
      width: 50% ;
      height: 30%;
    } */

  .p_popupT{
      width: 70%;
      font-size: 2.1vw;
  }

  .p_popupT{
    font-size: 16px !important;
  }

}



@media only screen and (min-width: 1000px) and (max-width: 1200px) and (orientation: landscape){

  .close{
    top: -3vh;
    right: 15vw;
  }

  .popup_inner{
    width: 40%;
  }

  .container{
    width: 940px;
    margin-top: -5%;
  }
  .buttons_container{
    height: 60px;
  }

  .card_canvas{
      justify-content: flex-start;
      margin-top: 3%;
  }

 .img_popup{
      width: 60%;
      height: 40%;
 }

 .videoE{
      width: 130px;
  }
  
  .p_popupV{
      width: 90%;
      font-size: 1.7vw;
  }

  .button_popupV{
    font-size: 1.7vw;
  }

  .div_img_popupV{
      align-items: center;
  }

  .info{
    position: sticky;
    left: 90.5%;
    cursor: pointer;
  }


  /* .popup_innerT {
      width: 30% ;
      height: 50%;
    } */

  .p_popupT{
      width: 90%;
      font-size: 1.7vw;
  }



  .container{
    width: 750px;
    margin-top: -30px;
  }


  .title_p{
    font-size: 16px;
    margin-top: 2%;
    margin-right: -3%;
  }

  .title_p2{
    font-size: 16px;
    margin-top: 2px;
  }


  .hand_buttons{
    align-items: center;
    justify-content: center;
    left: 70.5%;
    width: 5%;
    display: grid;
    padding-top: 1%;
    position: absolute;
    margin-bottom: 3%;
    z-index: 100;
    /* border: solid 1px #dedede; */
    background-color: rgba(255, 255, 255, 0.98);
    border-radius: 15px;
    box-shadow: 0 8px 17px 0 rgba(14, 9, 33, 0.2);
}

.menu_background_responsive{
  width: 75%;
}

.menu_draw{
    left: 8%;
    top: 120%;
}


.optionsDiv{
  width: 150px;
  left: 104%;
  top: -3%;
}

}


/* TOOLTIP */

.select_button {
  position: relative;
  display: inline;
  margin: 0 9px 6px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}


.select_button img{
  width: 26px;
}

.select_button span {
  visibility: hidden;
  width: fit-content;
  color: white;
  text-align: center;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  top: 0px;
  left: 60px;
  opacity: 0;
  transition: opacity 0.3s;
  color: #4c4c4c;

  padding: 10px 13px 8px 14px;
  border-radius: 18.5px;
  background-color: #ebebeb;
}


.select_button span:before {
  content:' ';
  display:block;
  position:absolute;
  left:-3px;
  top:10px;
  width:14px;
  height:14px;
  border-color:#ebebeb;
  border-width:1px;
  border-style:none none solid solid;
  background-color:#ebebeb;
  transform:rotate(45deg);
  -webkit-transform:rotate(45deg);
  -moz-transform:rotate(45deg);
}

.select_button:hover span {
  visibility: visible;
  opacity: 1;
}



.select_button2{
  position: relative;
  display: flow-root;
  background-color: transparent ;
  border: 0px;
  cursor: pointer;
  margin: 0 9px 0px;
  /* width: 100%; */
  width: -webkit-fill-available;
  height: 100%;
  text-align: center;
  padding: 0px;
}


.select_button2 img{
  width: 26px;
}

.select_button2 span {
  visibility: hidden;
  width: fit-content;
  color: white;
  text-align: center;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  top: 0px;
  left: 60px;
  opacity: 0;
  transition: opacity 0.3s;
  color: #4c4c4c;

  padding: 10px 13px 8px 14px;
  border-radius: 18.5px;
  background-color: #ebebeb;
}


.select_button2 span:before {
  content:' ';
  display:block;
  position:absolute;
  left:-3px;
  top:10px;
  width:14px;
  height:14px;
  border-color:#ebebeb;
  border-width:1px;
  border-style:none none solid solid;
  background-color:#ebebeb;
  transform:rotate(45deg);
  -webkit-transform:rotate(45deg);
  -moz-transform:rotate(45deg);
}

.select_button2:hover span {
  visibility: visible;
  opacity: 1;
}



/* 3 */


.select_button3{
  position: relative;
  display: inline;
  background-color: transparent ;
  border: 0px;
  cursor: pointer;
  margin: 0 9px 0px;
  /* width: 100%; */
  width: -webkit-fill-available;
  height: 100%;

}


.select_button3 img{
  width: 26px;
  margin: 0px 0px 0px !important;
}

.select_button3 span {
  visibility: hidden;
  width: fit-content;
  color: white;
  text-align: center;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  top: 0px;
  left: 60px;
  opacity: 0;
  transition: opacity 0.3s;
  color: #4c4c4c;

  padding: 10px 13px 8px 14px;
  border-radius: 18.5px;
  background-color: #ebebeb;
}


.select_button3 span:before {
  content:' ';
  display:block;
  position:absolute;
  left:-3px;
  top:10px;
  width:14px;
  height:14px;
  border-color:#ebebeb;
  border-width:1px;
  border-style:none none solid solid;
  background-color:#ebebeb;
  transform:rotate(45deg);
  -webkit-transform:rotate(45deg);
  -moz-transform:rotate(45deg);
}

.select_button3:hover span {
  visibility: visible;
  opacity: 1;
}



.draw_selector{
  background-image: url('../../assets/input_volume.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0px 10px;
}

#input_draw::-webkit-slider-runnable-track{
  height: 0px;
  visibility: hidden;
}

#input_draw::-webkit-slider-thumb{
  visibility: visible;
}
