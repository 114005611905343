

.zoomContainer{
    pointer-events: none;
}



#containerWall{
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    /* border: solid 1px red; */
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all .5s ease-in-out;
    background-size:cover;
}

#containerWallImg{  
    position: absolute;
    background-repeat: no-repeat;
    background-attachment: fixed;
    width: 100vw;
    height: 100vh;

    -o-background-size: cover;
    -moz-background-size: cover;
    -webkit-background-size: cover;
}

.logo{
    opacity: 1 !important;
    pointer-events: none;
}


#container{  
    position: relative;
    width: 100vw;
    height: 100vh;
    /* right: 20%; */
}

#container .point{
    opacity: 0;
}

#zoomCanvas{
    transition: all 0.7s ease-out 0s;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0,0,0);
}

#modePresentation{
    width: 25px;
    position: absolute;
    right: 4vw;
    top: 8vh;
    cursor: pointer;
}

.closeImg {
    width: 19px;
    position: absolute;
    right: -3px;
    top: -28px;
    display: none;
    cursor: pointer;
}

.video_player_div{
    width: 100%;
    height: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: block;
    cursor: pointer;
    background-color: rgba(0,0,0,0.6);
    z-index: 50;
    cursor: pointer;
}


.video_player{
    position: absolute;
    left: 50%;
    top: 50%;
    width: 27px;
    transform: translate(-50%, -50%);
}

.video_class{
    border-radius: 3px;
}

.photos_traslucid{
    opacity: 0.2 !important;
    -webkit-transition: opacity 1s ease-in-out !important;
    -moz-transition: opacity 1s ease-in-out !important;
    transition: opacity 1s ease-in-out !important;
}

.photos_animate_point{
    -webkit-transition: opacity 1s ease-in-out;
    -moz-transition: opacity 1s ease-in-out;
    transition: opacity 1s ease-in-out;
    opacity: 1;
}

.wall_logo{
    opacity: 0;
    cursor: default !important;
}


.ease {
    /* margin-top: 50px; */
    
    animation-name: enterMsgs;
  }
  
  @keyframes enterMsgs {
    0% {
      transform: scale(2);
      opacity: 1;
    }
  
    100% {
      transform: scale(1);
      opacity: 0;
    }
  }


.point {
    position: absolute;
    /* background-color: red; */
    font-family:mono;
    font-size:11px;
    text-align:center;
    /* border-radius:4px; */
    width:fit-content;
    height: 187px !important;
    border-radius: 6px;
    box-shadow: 0 4px 44px 0 rgba(0, 0, 0, 0.4);
    border: 3px solid white;
    transition: all 1s ease-in-out !important;
}

/* .hover_zoom{
    transition: all .5s ease-in-out !important;
} */

.hover_zoom:hover{ 
    -webkit-transform : rotate(0deg) scale(1.1) !important; 
    -ms-transform     : rotate(0deg) scale(1.1) !important; 
    transform         : rotate(0deg) scale(1.1) !important; 
}


.video_vertical-container{
    display: block;
    overflow: hidden;
    height: 187px;
}

/* @media only screen and (max-width: 600px) {

    #container{  
        right: 210%;
    }
}

@media only screen and (max-width: 1000px) and (orientation: landscape){

    #container{   
        right: 80%;
        top: -98vh;
    }
} */

/* @media only screen and (min-width: 1350px) {

    #container{  
        right: 20%;
    }
} */


    /* @media only screen and (min-width: 1920px) {

        #container{  
            right: 3%;
        }
    } */

 