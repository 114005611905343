.bg{
    /* background-color: rgba(28, 45, 86, 1); */
    height: 101vh;
    width: 100vw;
    position: absolute;
    top: 0;
    left: 0;
}

.log{
    width: 100vw;
    height: 30vh;
    display: flex;
    justify-content: center;
}

.log_divs{
    width: 33.3%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.logIMG{
    /* width: 70%;
    padding-top: 2%; */

    width: 60%;
    padding-top: 30%;

}



.card_container{
    opacity: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.close{
    width: 50%;
    position: relative;
    top: 6%;
    right: 1%;
    cursor: pointer;
}

.close_img{
    width: 5%;
}

.card{
    width: 460px;
    height: 610px;
    border-radius: 10px;
    box-shadow: 0 18px 28px 0 rgba(0, 0, 0, 0.3);
    border: solid 1px #848484;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 10;
}

.card_p1{
    width: 85%;
    font-size: 1vw;
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
    color: #121d37;
    text-align: center;
    line-height: 1.3;
    margin-top: 5px;
}

.card_p2{
    font-size: 20px;
    font-family: "Poppins";
    font-weight: 600;
    color: #C68A12;
    text-align: center;
    line-height: 1.3;
}

.swip_container{
    width: 100vw;
    height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
}



.button_container{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 414px;
    
}

.button{
    width: 70%;
    height: 70%;
    padding: 0;
    font-family: "Poppins";
    font-size: 15px;
    border-radius: 100%;
    box-shadow: 0 18px 28px 0 rgba(0, 0, 0, 0.3);
    background-color: #C68A12;
    color: #ffffff;
    border: 5px solid #C68A12;
    cursor: pointer;
}

.close{
    position: relative;
    right: -220px;
    top: -45px;
    width: fit-content;
    cursor: pointer;
    z-index: 11;
}

.container_close{
    height: 0vh;
}

button:focus {outline:0;}

.send_pencil{
    padding-left: 7%;
}

.expP{
    color: #4D4D4D;
    font-size: 0.9vw;
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
    cursor: pointer;
    margin-top: 1.5%;
    margin-bottom: 3%;
}

.footer{
    width: 100vw;
    height: 20vh;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.footerP{
    width: 33,3%;
}

.footerSegundoP{
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
    font-size: 0.9vw;
    padding-bottom: 1%;
    color: white
}


.videoSignHidden{
    position: absolute;
    visibility: hidden;
    top: 0;
    left: 10vw;
    /* background-color: red; */
}

.recordDiv{
    height: 120px;
    width: 120px;
    background: #f7f7f7;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
}

.buttonStop{
    width: 40%;
    height: 40%;
    padding: 1vh 0vh;
    font-family: 'Open Sans', sans-serif;
    font-size: 0.9vw;
    border-radius: 30%;
    box-shadow: 0 18px 28px 0 rgba(0, 0, 0, 0.3);
    background-color: #C68A12;
    color: #ffffff;
    border: 5px solid #C68A12;
    cursor: pointer;
}

.containerPreview{
    width: 100%;
    height: 10%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
    margin-bottom: 40px;
}

.cancelPreview{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    cursor: pointer;
}

.angle_left{
}

.button_back{
    width: 50%;
    height: 48px;
    font-size: 16px;
    font-weight: 400;
    font-family: "Poppins";
    background-color: transparent;
    border: transparent;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    background-color: #f2f2f2;
    margin-top: 10px;
    color: #121d37;
    cursor: pointer;
}

.button_back p{
    display: flex;
    justify-content: space-between;
}

.buttonSubmit{
    height: 55px;
    width: 50%;
    border-radius: 30px;
    box-shadow: 0 5px 23px 0 rgba(11, 9, 33, 0.2);
    font-size: 16px;
    font-family: "Poppins";
    font-weight: 600;
    border: solid 2px #C68A12 ;
    background-color: #C68A12 ;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    cursor: pointer;
}

p{
    margin: 0px;
    text-align: center;
    width: 80%;
}

/* MEDIA QUERIES CELLPHONE */


@media only screen and (max-width: 600px) {

    .log_divs{
        width: 70%;
    }

    .logIMG{
        /* width: 90%; */
        width: 70%;
        padding-top: 55%;
    }
    
    .card_container{
        justify-content: center;
        height: 100vh;
        /* margin-top: 15%; */
    }

    .card{
        width: 90%;
        height: 67%;
        min-height: 530px;
    }

    .card_p1{
        font-size: 3vw;
    }
    
    .card_p2{
        font-size: 5vw;
    }

    .button_container{
        width: 100%;
        height: 344px;
    }

    .button{
        padding: 0vh 0vh;
        font-size: 3vw;
    }

    .expP{
        font-size: 3vw;
        margin-top: 5%;
        margin-bottom: 5%;
    }

    .close{
        right: -42vw;
        top: -5vh;
    }

    .containerPreview{
        flex-direction: column;
        height: 20%;
        flex-wrap: nowrap;
        margin-bottom: 0px;
    }

    .buttonSubmit{
        width: 55%;
    }

    .button_back{
        width: 55%;
    }

}
