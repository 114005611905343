.bg{
    /* background-image: url('/assets/1mm_background.webp'); */
    background-repeat: no-repeat !important;
    overflow: hidden;
    /* position: */
    background-size: cover;
    z-index: 10;
}


p{
    margin: 0px;
    text-align: center;
    width: 80%;
}

.log{
    width: 100vw;
    height: 30vh;
    display: flex;
    justify-content: center;
}

.log_divs{
    width: 33.3%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.logIMG{
    width: 320px;
}

.login{
    width: 100vw;
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;   
}

.form{
    /* opacity: 0; */
    width: 380px;
    height: 330px;
    border-radius: 12px;
    box-shadow: 0 12px 32px 8px rgba(7, 6, 34, 0.47);
    background-color: #ffffff;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
}

.one, .two, .three{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.primerOne{
    color: #414141;
    width: 100%;
    font-size: 18px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 500;
    margin-top: 8%;
}

.segundoOne{
    font-size: 1.1vw;
    font-family: 'Open Sans', sans-serif;
    color: #1e5680;
    width: 70%;
    padding-top: 1%;
    font-weight: 300;
    margin-top: 0%;
}

.two{
    justify-content: center;
}

.primerTwo{
    font-size: 13px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
    color: #aeaeae;
}

.input{
    width: 220px;
    height: 30px;
    padding: 8px 0;
    border-radius: 30px;
    box-shadow: 0 6px 19px 0 rgba(0, 0, 0, 0.1);
    border: solid 1px #0d0d31;
    margin: 0 auto;
    display: block;
    text-align: center;
    font-family: 'Open Sans';
    font-size: 16px;
    color: #323232;
    margin-top: 3%;
    text-transform: uppercase;
    font-weight: normal;
}

::placeholder {
    text-align: center; 
    opacity: 0.64;
    font-family: 'Open Sans';
    font-size: 16px;
    color: #414141;
}

.input::placeholder {
    text-align: center;
    padding: 0;
}
 

.alert{
    color: #e6294a;
    margin-top: 2%;
    font-size: 0.8vw;
    font-family: 'Open Sans', sans-serif;
}

.buttonLogin{
    width: 150px;
    height: 50px;
    border-radius: 30px;
    box-shadow: 0 5px 23px 0 rgb(11 9 33 / 20%);
    background-color: #C68A12;
    border: none;
    font-family: 'Open Sans', sans-serif;
    font-weight: 500;
    color: #ffffff;
    font-size: 0.9vw;
    cursor: pointer;
    margin-bottom: 15%;
    font-size: 16px;
}

.buttonLogin:hover{
    background-color: #9B6C0D;
}

button:focus {outline:0;}

.footer{
    width: 100vw;
    height: 20vh;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.footerP{
    width: 33,3%;
}

.footerSegundoP{
    margin-bottom: 12px;
    padding: 0;
}



/* POPUP */
.popup {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color:rgba(0, 0, 0, 0.5);
    z-index: 9001;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .popup_inner {
    width: 30% ;
    height: 40%;
    background: #fdfdfd;
    text-align: center;
    box-shadow: 0 18px 31px 0 rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  
  .container_popup{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }
  
  .div_img_popup{
    width: 100%;
    display: flex;
    justify-content: center;
  }
  
  .img_popup{
    width: 30%;
    }

.videoE{
    width: 180px;
}

  .p_popup{
    color: #38577e;
    width: 70%;
    font-family: 'Open Sans', sans-serif;
    font-size: 1.1vw;
    font-weight: 300;
  }
  
  .pw_popup{
    font-weight: 500;
  }
  
  .button_popup{
    font-family: 'Open Sans', sans-serif;
    font-size: 1vw;
    color: #c92745;
    font-weight: 500;
    cursor: pointer;
    border: none;
    background: none;
  }


.send_button{
    border-radius: 28.5px;
    box-shadow: 0 18px 28px 0 rgba(0, 0, 0, 0.3);
    background-color:#E73493;
    border: 5px solid #E73493;
    font-size: 15px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 500;
    color: #ffffff;
    cursor: pointer;
    width: 200px;
}


/* MEDIA QUERIES CELLPHONE */
@media only screen and (max-width: 600px) {

    .log_divs{
        width: 70%;
    }

    .logIMG{
        width: 90%;
    }

    .form{
        width: 90%;
        height: 80%;
        margin-top: -20%;
    }

    .primerOne{
        color: #1e5680;
        font-size: 5vw;
        width: 80%;
    }
    
    .segundoOne{
        font-size: 4vw;
        width: 80%;
        padding-top: 3%;
        margin-top: 1%;
    }

    .primerTwo{
        font-size: 2.8vw;
        margin-top: 3%;
    }

    .footer{
        width: 100vw;
        height: 20vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .alert{
        color: #e6294a;
        margin-top: 2%;
        font-size: 2.5vw;
    }

    .input{
        width: 70%;
        padding: 1.5vh 0vh;
        border-radius: 28px;
        border: solid 1px #979797;
        margin-top: 2%;
        font-size: 4.3vw;
        height: 3vh;
    }

    .buttonLogin{
        width: 70%;
        font-size: 4.5vw;
        padding: 2%;
        height: 7vh;
    }

    .footerP{
        width: 33,3%;
    }

    
    
    .footerSegundoP{
        font-family: 'Open Sans', sans-serif;
        font-weight: 300;
        font-size: 2.7vw;
        padding-bottom: 20%;
        color: white
    }

    .footer{
        width: 100vw;
        height: 20vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .popup_inner {
        width: 60% ;
        height: 40%;
        background: #fdfdfd;
        text-align: center;
        box-shadow: 0 18px 31px 0 rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        position: absolute;
        left: 50%;
        top: 52%;
        transform: translate(-50%, -50%);
    }

    .p_popup{
        color: #4D4D4D;
        width: 90%;
        font-family: 'Open Sans', sans-serif;
        font-size: 3.5vw;
        font-weight: 300;
    }

    .send_button{
        border-radius: 28.5px;
        box-shadow: 0 18px 28px 0 rgba(0, 0, 0, 0.3);
        background-color:#E73493;
        border: 5px solid #E73493;
        font-size: 15px;
        font-family: 'Open Sans', sans-serif;
        font-weight: 500;
        color: #ffffff;
        cursor: pointer;
        width: 200px;
        height: 5vh;
    }
  }
  /* @media only screen and (min-width: 700px) and (max-width: 900px) and (orientation: portrait){

    .log_divs{
        width: 70%;
    }

    .form{
        width: 50%;
        height: 80%;
    }

    .buttonLogin{
        font-size: 12px;
    }

    .primerOne{
        color: #1e5680;
        font-size: 2.3vw;
        margin-top: 10%;
        width: 85%;
    }

    .segundoOne{
        font-size: 1.9vw;
        width: 80%;
        padding-top: 3%;
        margin-top: 1%;
    }

    .primerTwo{
        font-size: 1.5vw;
        margin-top: 9%;
    }

    .three{
        justify-content: space-between;
        font-size: 2vw;
    }

    .button{
        font-size: 1.7vw;
        margin-bottom: 1%;
    }

    .footer{
        align-items: center;
    }

    .footerSegundoP{
        font-size: 1.7vw;
        margin-bottom: 5%;
    }

    .popup_inner {
        width: 40% ;
        height: 30%;
        background: #fdfdfd;
        text-align: center;
        box-shadow: 0 18px 31px 0 rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    .p_popup{
        color: #38577e;
        width: 90%;
        font-family: 'Open Sans', sans-serif;
        font-size: 1.5vw;
        font-weight: 300;
    }
  } */

  /* @media only screen and (min-width: 700px) and (max-width: 900px) and (orientation: landscape){

    .popup_inner {
        width: 30% ;
        height: 50%;
        background: #fdfdfd;
        text-align: center;
        box-shadow: 0 18px 31px 0 rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        position: absolute;
        left: 50%;
        top: 60%;
        transform: translate(-50%, -50%);
    }

    .p_popup{
        color: #4D4D4D;
        width: 90%;
        font-family: 'Open Sans', sans-serif;
        font-size: 1.5vw;
    }

    .send_button{
        border-radius: 28.5px;
        box-shadow: 0 18px 28px 0 rgba(0, 0, 0, 0.3);
        background-color:#E73493;
        border: 5px solid #E73493;
        font-size: 15px;
        font-family: 'Open Sans', sans-serif;
        font-weight: 500;
        color: #ffffff;
        cursor: pointer;
        width: 200px;
        height: 8vh;
    }
  } */

  /* @media only screen and (min-width: 1000px) and (max-width: 1200px) and (orientation: landscape){

    .form{
        margin-top: 0%;
        width: 30%;
        height: 80%;
    }


    .input{
        font-size: 12px;
    }

    .login{
        align-items: flex-start;
    }

    .footer{
        align-items: flex-end;
    }

    .footerSegundoP{
        font-size: 1vw;
        margin-bottom: 1%;
    }

    .buttonLogin{
        font-size: 12px;
    }
  } */

 