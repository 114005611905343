
.bg{
    /* background-color: rgba(28, 45, 86, 0.98); */
    height: 100svh;
    width: 100svw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
  }

p{
    margin: 0px;
    text-align: center;
    width: 80%;
}

.back{
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #969696;
  width: fit-content;
  font-family: 'Poppins';
}

.angle_left{
    width: 4px;
    height: 8px;
    margin: 5px 13px 6px 0;
    opacity: 0.89;
    cursor: pointer;
}

.log{
    width: 100vw;
    height: 30vh;
    display: flex;
    justify-content: center;
}

.log_divs{
    width: 33.3%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.log_div3{
    width: 33.3%;
    position: relative;
    top: 18%;
    left: 12.5%;
}

.logIMG{
    width: 320px;
}

.login{
    width: 100vw;
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 12;
    opacity: 0;
}

.form{
    /* opacity: 0; */
    width: 380px;
    height: fit-content;
    border-radius: 12px;
    box-shadow: 0 12px 32px 8px rgba(7, 6, 34, 0.47);
    background-color: #ffffff;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
}

.one, .two_1, .two_2, .three, .four{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.one{
    margin-top: 12%;
}

.two_1{
    justify-content: flex-start;
    margin-top: 5%;
}

.two_2{
    justify-content: flex-start;
    margin-top:5%;
}

.three{
    margin-top: 25px;
    margin-bottom: 20px;
}

.four{
    margin-bottom: 10%;
    
}

.primerOne{
    color: #0d0d31;
    font-size: 18px;
    line-height: 25px;
    font-family: 'Poppins';
    width: 95%;
}

.primerOne span{
    background: linear-gradient(90deg, #E3BE7C 0%, #C68A12 100%);
    -webkit-background-clip: text;
    color: transparent; 
}

.primerTwo{
    font-size: 0.8vw;
    font-family: 'Open Sans';
    font-weight: 300;
    color: #aeaeae;
    padding-top: 3%;
}

.input{
    width: 75%;
    height: 40px;
    font-family: 'Poppins';
    box-shadow: 0 6px 19px 0 rgba(0, 0, 0, 0.1);
    border: solid 1px #0d0d31;
    margin: 0 auto;
    display: block;
    text-align: left;
    justify-content: center;
    margin-top: 2%;
    font-size: 16px;
    color: #0d0d31;
    padding: 8px 0;
    padding-left: 20px;
    border-radius: 30px;
}

input:focus{
    border: 1px solid #1C2D56;
    outline-offset: 0px;
    outline: none;
}

input::placeholder {
    opacity: 0.64;
    font-family: 'Open Sans';
    font-size: 16px;
    font-weight: normal;
    text-align: left;
    vertical-align: middle;
    color: #414141;
}

.alert{
    color:#e6294a;
    margin-top: 2%;
    font-size: 0.8vw;
    font-family: 'Open Sans', sans-serif;
}

.button{
    width: 80%;
    height: 60px;
    border-radius: 4px;
    box-shadow: 0 5px 23px 0 rgba(11, 9, 33, 0.2);
    background-color: #C68A12;
    border: none;
    border-radius: 30px;
    font-family: 'Poppins';
    font-weight: 500;
    color: #ffffff;
    font-size: 16px;
    cursor: pointer;
}


.button:hover{
    background-color: #9B6C0D;
}

.button_back{
    background-color: transparent;
    border: transparent;
    display: flex;
    cursor: pointer;
}

button:focus {outline:0;}

.footer{
    opacity: 0;
    width: 100vw;
    height: 20vh;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    z-index: 10;
}

.close{
    position: relative;
    right: 11vw;
    top: -2vh;
    cursor: pointer;
}

/* MEDIA QUERIES CELLPHONE */
@media only screen and (max-width: 600px){

    .log{
        height: 25vh;
    }

    .log_divs{
        width: 70%;
        margin-bottom: 20px;
    }

    .logIMG{
        width: 180px;
    }

    .primerOne{
        font-size: 4.3vw;
        width: 95%;
    }

    .primerTwo{
        font-size: 3vw;
    }

    .input{
        margin-top: 5%;
    }


    input::placeholder {
        font-size: 0.8rem;
    }

    .button{
        /* background-color: #00a3df;
        border: 5px solid #00a3df; */
    }

    .close{
        position: relative;
        right: 28vw;
        top: -2vh;
        cursor: pointer;
    }

    .alert{
        color:#e6294a;
        margin-top: 2%;
        font-size: 0.7rem;
        font-family: 'Open Sans', sans-serif;
    }


    .three{
        margin-top: 7%;
        margin-bottom: 4%;
    }
    
}


@media only screen and (max-width: 600px) and (max-height: 650px) {

    .primerOne{
        padding: 0%;
    }

    .three{
        margin-top: 7%;
        margin-bottom: 5%;
    }

    .two_1{
        margin-top: 0%;
    }
}




@media only screen and (min-width: 700px) and (max-width: 900px) and (orientation: portrait){

    .log_divs{
        width: 70%;
    }

    .login{
        align-items: flex-start;
        margin-top: 3%;
    }

    .form{
        width: 45%;
    }

    .primerOne{
        font-size: 2vw;
    }

    .primerTwo{
        font-size: 2vw;
    }

    .input{
        font-size: 2.3vw;
    }

    .button{
        font-size: 1.7vw;
        width: 65%;
    }

    .close{
        position: relative;
        right: 22vw;
        top: -2vh;
        cursor: pointer;
    }
}

/* @media only screen and (min-width: 1000px) and (max-width: 1200px) and (orientation: landscape){

    .login{
        align-items: flex-start;
        margin-top: 3%;
    }


    .form{
        width: 30%;
        height: 90%;
    }

    .button{
        width: 70%;
        height: 100%;
        font-size: 1.2vw;
    }

    .primerOne{
        font-size: 1.5vw;
    }

  } */
